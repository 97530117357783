<template>
  <div class="container mt-5">
    <div class="row border mt-4">
      <div class="col-sm-12 mt-4 mb-4">
        <h5>Payslip - {{item.month}}/{{item.year}}</h5>
      </div>
      <div class="col-sm-4">
        <h5>Employee Code:  {{emp_item.code}}</h5> 
        <br/>
        <h5>Employee Name: {{emp_item.title}}</h5>
      </div>
      <div class="col-sm-4">
        <h5>Attendance: {{item.days}}</h5>
        <br/>
        <!-- <h5>Branch : </h5> -->
        <!-- <br/> -->
        <!-- <h5>Department: </h5> -->
        <!-- <br/> -->
      </div>
      <div class="col-sm-4">
        <h5>Absent: {{31 - item.days}}</h5>        
        <br/>
      </div>
    </div>
    <div class="row border">
      <div class="col-sm-3 mt-1">
        <h5>Basic Salary:  {{item.total_monthly_salary}}</h5> 
      </div>
      <div class="col-sm-3 mt-1">
        <h5>Gross Salary:  {{item.gross_salary}}</h5> 
      </div>
      <div class="col-sm-3 mt-1">
        <h5>Over Time:  {{item.ot}}</h5> 
      </div>

      <div class="col-sm-3 mt-1">
        <h5>Net Salary:  {{item.net_salary}}</h5> 
      </div>
    </div>
    <div class="row ">
      <div class="col-sm-3 border mt-1">
        <table>
          <tr><td colspan="2"> <h4>  Monthly Allowances </h4></td> </tr>
          <tr>
            <td>Housing</td>
            <td>{{item.housing}}</td>
          </tr>
          <tr>
            <td>Transportation</td>
            <td>{{item.transportation}}</td>
          </tr>
          <tr>
            <td>Other</td>
            <td>{{item.other_allowance}}</td>
          </tr>
          <tr>
            <td>Telephone</td>
            <td>{{item.telephone}}</td>
          </tr>
          <tr>
            <td>Other</td>
            <td>{{item.other_allowance}}</td>
          </tr>
          <tr>
            <td>Supervisory</td>
            <td>{{item.supervisory_allowance}}</td>
          </tr>
          <tr>
            <td>Driving</td>
            <td>{{item.driving_allowance}}</td>
          </tr>
          <tr>
            <td>Work Permit</td>
            <td>{{item.work_permit}}</td>
          </tr>
          
          <tr>
            <td>Food</td>
            <td>{{item.food_allowance}}</td>
          </tr>
          <tr>
            <td>KJO</td>
            <td>{{item.kjo}}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{{item.total_monthly_allowance}}</td>
          </tr>
        </table>
      </div>
      <div class="col-sm-3 border mt-1">
        <table>
          <tr><td colspan="2"> <h4> Monthly Deductions </h4></td> </tr>
          <tr>
            <td>GOSI </td>
            <td>{{item.gosi}}</td>
          </tr>
          <tr>
            <td>Absence</td>
            <td>{{item.absence}}</td>
          </tr>
        </table>
      </div>
      <div class="col-sm-3 border mt-1">
        <table>
          <tr><td colspan="2"> <h4>   Non Monthly Allowances </h4></td> </tr>
          <tr>
            <td>018 Account</td>
            <!-- <td>{{item.temp_allowance}}</td> -->
            <td>0.00</td>
          </tr>
          <tr>
            <td>Temp</td>
            <td>{{item.temp_allowance}}</td>
          </tr>
          <tr>
            <td>OT</td>
            <td>{{item.ot}}</td>
          </tr>
          <tr>
            <td>Prev. Month OT</td>
            <td>{{item.previous_month_ot}}</td>
          </tr>
          <tr>
            <td>Non Monthly Addition</td>
            <td>{{item.non_monthly_addition}}</td>
          </tr>
        </table>
      </div>
      <div class="col-sm-3 border mt-1">
        <table>
          <tr><td colspan="2"> <h4> Non Monthly Deductions </h4></td> </tr>
          <tr>
            <td>Absent Dedallow</td>
            <td>{{item.absent_dedallow}}</td>
          </tr>
          <tr>
            <td>Non-Monthly Deduction</td>
            <td>{{item.non_monthly_deduction}}</td>
          </tr>
          <tr>
            <td>Previous Month Absences</td>
            <td>{{item.previous_month_absences}}</td>
          </tr>
          <tr>
            <td>CA-Traffic-Fines Deduction </td>
            <td>{{item.ca_trafficfines_deduction}}</td>
          </tr>
          <tr>
            <td> Placement Fees </td>
            <td>{{item.placement_fees}}</td>
          </tr>
          <tr>
            <td>Bank Fee </td>
            <td>{{item.bank_fee}}</td>
          </tr>
          

        </table>
      </div>
    </div>
    <div class="row border mt-4">
      <div class="col-sm-6 mt-4">
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <h5 class="border-top">Employee Signature</h5>
      </div>
      <div class="col-sm-6 mt-4">
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <h5 class="border-top">Approved By</h5>
      </div>
      <div class="col-sm-4">
      </div>
    </div>
  </div>
</template>
<script>
import HRMService from "../../../services/hrm-service";
const VueQrcode = require("@chenfengyuan/vue-qrcode");

const converter = require("number-to-words");

export default {
  name: "Payslip",
  components: {
    VueQrcode: VueQrcode,
  },
  data: function () {
    return {
      
      item: {},
      emp_item: {}
    };
  },
  mounted() {
    this.get_data(this.$route.params.id);
  },
  methods: {

  get_data(params) {

    HRMService.employee_payrollById(params)
      .then((response) => {
        this.item = response.data;
        this.total_nm_allowance = Number(this.item['ot']) + Number(this.item['previous_month_ot']) +  Number(this.item['non_monthly_addition'])
        this.allowance = this.total_nm_allowance + Number(this.item['total_monthly_allowance'])
        this.deduction =  Number(this.item['total_NM_deduction']) + Number(this.item['gosi'])
        this.absent_days = 31 - Number(this.item['days'])


      HRMService.get_employee(this.item['employee'])
      .then((response) => {
        this.emp_item = response.data['employee'];
      })
      .catch((e) => {
        console.log(e);
      });

      })
      .catch((e) => {
        console.log(e);
      });

  }

  },
};
</script>
<style scoped>
  .border, .border-top{
    border-color: black !important;
  }
</style>